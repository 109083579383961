/* eslint-disable */
import React from 'react'
import loadable from '@loadable/component'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import ReactDOM from 'react-dom'
import {HorizontalBar} from 'react-chartjs-2'
//import '../components/alice-carousel.scss'

//import Typewriter from 'typewriter-effect';

import { defaults } from 'react-chartjs-2';

import logo from '../img/logo-ev1a.svg'

const OfferRoll2 = loadable(() => import('../components/OfferRoll2'));
const BlogRoll2 = loadable(() => import('../components/BlogRoll2'));
const BriefCall = loadable(() => import('../components/BriefCall'));
const SliderOpinions = loadable(() => import('../components/SliderOpinions'));
const PartnerSlider = loadable(() => import('../components/PartnerSlider'));
const AliceCarousel = loadable(() => import('react-alice-carousel'));

//import FormMsg from '../components/FormMsg'

defaults.global.defaultFontColor = '#36b3d2';


let data2={
  labels: ['','2026','2025','2024','2023','2022','2021','2020','2019','2018','2017','2016','2015','2014',''],
 datasets: [
   {
     label: 'e-commerce 2014-2026',
     fill: true,
     backgroundColor: ['#1183c8','#96edf0','#30c0ca','#41aec9','#3dbbd5','#1db4cb','#2ea1b8','#36b3d2','#1e7b9e','#1183c8','#3dbbd5','#30c0ca','#41aec9','#3dbbd5'],
     borderColor: 'rgba(113, 101, 248,0.75)',
     hoverRadius: 10,
     borderWidth: 0,
     hoverBackgroundColor: '#111111',defaultFontColor: '#111111',
     data: [
            0,
            8148,
            7528,
            6913,
            6310,
            5717,
            5211,
            4248,
            3351,
            2982,
            2382,
            1845,
            1548,
            1336,
            0,
          ]
   }

 ]
}




export default class Index extends React.Component {

  componentDidMount(){

      var lazyBackgrounds = [].slice.call(
        document.querySelectorAll(".lazy-background")
      );

      if (
        "IntersectionObserver" in window &&
        "IntersectionObserverEntry" in window &&
        "intersectionRatio" in window.IntersectionObserverEntry.prototype
      ) {
        let lazyBackgroundObserver = new IntersectionObserver(function (
          entries,
          observer
        ) {
          entries.forEach(function (entry) {
            if (entry.isIntersecting) {
              entry.target.classList.add("visible");
              lazyBackgroundObserver.unobserve(entry.target);

            }
          });
        });
        lazyBackgrounds.forEach(function (lazyBackground) {
          lazyBackgroundObserver.observe(lazyBackground);
        });
      }
    ;
    //animateIcons();
  }

render() {
  return (
    <>
    <Helmet titleTemplate="Copywriting agency - SEO - translations - e-multicontent.com">
      <title>Copywriting agency - SEO - translations - e-multicontent</title>
      <meta name="description" content={'✍🏻 We write for 🛒 e-commerce in Polish and in other languages, taking SEO into account - we are not a translation agency.'}/>
      <meta name='image' content={'https://e-multicontent.com/img/meta/home-meta.jpg'} />
      <meta name='keywords' content={'copywriting, copywriters, marketing, copywriters agency, Wrocław, team of copywriters, work, business, content, content, translations, SEO, blog, texts, articles, graphics, video, websites, online stores, marketplace, e-commerce'} />


      <meta property="og:description" content={'✍🏻 We write for 🛒 e-commerce in Polish and in other languages, taking SEO into account - we are not a translation agency.'}/>
      <meta property="og:title" content={'Copywriting agency - SEO - translations - e-multicontent.com'} />
      <meta property="og:image" content={'https://e-multicontent.com/img/meta/home-meta.jpg'} />
      <meta property="og:url" content={'https://e-multicontent.com'}/>

      <link rel="alternate" href="https://e-multicontent.pl" hrefLang="pl-pl" />
      <link rel="canonical" href="https://e-multicontent.com/"  />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="e-multicontent.com" />
      <meta property="twitter:url" content="https://e-multicontent.com/" />
      <meta name="twitter:title" content="Copywriting agency - SEO - translations - e-multicontent.com" />
      <meta name="twitter:description" content="✍🏻 We write for 🛒 e-commerce in Polish and in other languages, taking SEO into account - we are not a translation agency." />
      <meta name="twitter:image" content="https://e-multicontent.com/img/apple-touch-icon.png" />

      <script type='application/ld+json'>
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://e-multicontent.com"
        }]
      })}
      </script>

    </Helmet>
  <Layout>
  <div id="hero-home">
  {/* image module */}
    <div
      id="up"
      className="full-width-image margin-top-0"
      style={{
        backgroundAttachment: `contain`,
        transform: 'scale(1)',
        top:'0px',
        backgroundRepeat:'no-repeat',
        zIndex: '-5',
        width: '100%'

      }}
    ></div>
      <div
        className="mright"
        style={{
          display: 'flex',
          position: 'absolute',
          left: '10%',
          lineHeight: '1',
          justifyContent: 'space-around',
          flexDirection: 'column',
          top: '100px',
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
          borderRadius: '15px'
        }}
      >

      <a
        href="/#offer"
        rel="noopener noreferrer"
        style={{borderRadius: '5px',backgroundColor:'rgba(0,0,0,0.0)'}}
      >
        <img src={logo} alt="SEO copywriting e-commerce opisy produktów w językach obcych, copywriter Wrocław - e-multicontent.pl"
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen "
          style={{
            color:'#252529',
            lineHeight: '1',
            padding: '0.25em',
            width: '400px',
            marginLeft:'-33px',
            marginBottom:'-2px',
          }}
        /><h1
        className="h1title"
        style={{
          color:'#4a4a4a',
          lineHeight: '1.3',
          padding: '0.35em',
          fontSize:'1.15em',
          maxWidth:'450px',
        }}>
        Multilingual copywriting<br /> and content agency.
        </h1>
        <br></br>


        <h2
          style={{
            color: '#4a4a4a',
            lineHeight: '1.2',
            padding: '0.35em',
            fontSize: '.9em',
            textAlign: 'left',
            maxWidth:'90%',
          }}
        >
        <strong>Effective copywriting agency.</strong>
        <br />
        We create <strong>SEO optimized content</strong>,
        <br />
        for e-commerce. <strong>In many languages</strong>.
        </h2>
        <br></br>

        <br></br>
        </a>










      </div>


    {/*Content*/}
    <section  className="section section--gradient"
    style={{padding:'0px',margin:'0px',width:'100%',left:'0%'}}
     >


     <p className="ux" align="center" id="offer"><a className="btnoutline" href="/#our-offer">⇩</a></p>
     <div className="columns"
     style={{marginTop:'0px'}}
     >
       <div className="column is-12">

         <h3 id="our-offer" className="has-text-weight-semibold is-size-2"
         style={{marginLeft:'7.75%',paddingTop:'50px',maxWidth:'85%',display:'flex',flexWrap:'wrap'}}
         >Content, that will&nbsp;<span style={{color:'#36b3d2'}}>build your business.</span>
         </h3>
         <br />
         <div style={{marginLeft:'7.75%',maxWidth:'75%'}}>
         <p style={{fontSize:'1.5em'}}><strong>Multilingual copywriting and content marketing agency</strong> <b><span style={{color:'#36b3d2'}}>e</span>-multicontent</b> -  check out our offer: </p>
         <p>From <strong>SEO optimized descriptions of products through copywriting in various languages to running company blogs and online stores and creating content for their use</strong>. We are a multilingual <Link className="dedlink" to="/offer/">content</Link> marketing agency specializing in web and <Link className="dedlink" to="/offer/e-commerce-package-subscriptions/">content writing for the e-commerce industry</Link>.
         <br /><br />
         If you need efficient <Link className="dedlink" to="/offer/e-commerce-package-subscriptions/">product and category descriptions for your online store</Link>, interesting <Link className="dedlink" to="/offer/copywriting-pl/">blog entries</Link> or engaging <Link className="dedlink" to="/offer/multilingual-copywriting/">texts for your company's website</Link>, you have come to the right place! We create content in as many as <strong>35 different languages</strong>, working simultaneously in each of them! This way you don't waste time translating - <strong>our professionals work on a complete sets of texts in various languages</strong> at the same time.
         </p>
         </div>
         {/*oferta*/}

         <OfferRoll2 />
         <div className="has-text-centered">
           <Link className="btn" to="/offer/" style={{fontSize:'1.5em'}}>
             Check out our offer &nbsp; &nbsp;
             <img width="15px" height="22px" style={{verticalAlign:'middle', marginRight:'5px'}}   alt="copywriting, e-commerce, marketplace, seo and translations" src='/img/angle-right.svg'/>
           </Link>
           <br /><br />
         </div>
       </div>
     </div>

        <BriefCall />

        <div style={{backgroundColor:'#ffffff',width:'100%',padding:'50px',marginLeft:'0px'}}>
                <div className="content" style={{marginTop:'50px',marginBottom:'50px',marginLeft:'5%'}}>
                    <h2 className="is-size-2">
                    See how we can <b style={{color:'#36b3d2'}}>help you</b>.
                    </h2>
                    <h3 style={{fontSize:'1em',width:'85%',fontWeight:'400'}}>We create e-multicontent for e-commerce: <strong>from product descriptions to content for blogs</strong>.<br /> In Polish and in over 35 various other languages.</h3>
                    <p style={{fontSize:'1em'}}>Why work with us?</p>
                </div>

                <br></br>
                <br></br>

                <div style={{marginLeft:'5%',marginRight:'5%',marginBottom:'50px'}}>
                <div id="atuty" className="columns" style={{textAlign:'center',width:'100%',margin:'auto'}}>

                  <div className="column">
                  <div style={{textAlign:'center'}}>
                    <img className="atut" loading="lazy" src="/img/fa-icons/users-solid.svg" style={{margin:'10px'}} width="50px" height="50px" alt="a team of experienced copywriters with knowledge of SEO and e-commerce"/>
                    </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}>We are a team of <strong>experienced copywriters - native speakers</strong> with marketing knowledge</p>

                  </div>

                  <div className="column">
                  <div style={{textAlign:'center'}}>
                    <img className="atut" loading="lazy" src="/img/fa-icons/glasses-solid.svg" style={{margin:'10px',}} width="50px" height="50px" alt="SEO content for websites and online stores"/>
                    </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}> We create or translate <strong>content in dozens of languages</strong> from scratch </p>

                  </div>

                  <div className="column">
                  <div style={{textAlign:'center'}}>
                    <img className="atut" loading="lazy" src="/img/fa-icons/chess-solid.svg" style={{margin:'10px',}} width="50px" height="50px" alt="Translations in over 35 languages and SEO content marketing for websites"/>
                    </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}> We <strong>create and implement</strong> a tailored made content marketing strategy, coordinating all of its elements <strong>from start to finish</strong>. </p>

                  </div>

                  <div className="column">
                  <div style={{textAlign:'center'}}>
                    <img className="atut" loading="lazy" src="/img/fa-icons/quote-right-solid.svg" style={{margin:'10px',}} width="50px" height="50px" alt="Unique texts - SEO copywriting for websites"/>
                    </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}> We create unique texts <strong>without duplicating content</strong> </p>

                  </div>

                  <div className="column">
                  <div style={{textAlign:'center'}}>
                    <img className="atut" loading="lazy" src="/img/fa-icons/text-height-solid.svg" style={{margin:'10px',}} width="50px" height="50px" alt="Content optimization for SEO - copywriting"/>
                    </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}> We use the <strong>language of benefits</strong>, avoiding fixed phrases and clichés </p>

                  </div>
                </div>

                <div className="columns" style={{textAlign:'center',margin:'auto',marginTop:'25px'}}>

                  <div  className="column">
                  <div style={{textAlign:'center'}}>
                    <img className="atut" loading="lazy" src="/img/fa-icons/spell-check-solid.svg" style={{margin:'10px',}} width="50px" height="50px" alt="Experienced native-speakers copywriters"/>
                    </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}> Our characteristic feature is <strong>grammatical, stylistic and spelling accuracy</strong> in each language </p>

                  </div>

                  <div  className="column">
                  <div style={{textAlign:'center'}}>
                    <img className="atut" loading="lazy" src="/img/fa-icons/map-pin-solid.svg" style={{margin:'10px',}} width="50px" height="50px" alt="Multilingual team copywriting / SEO copywriter"/>
                    </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}>We adapt content to the <strong>requirements of a specific market and the characteristic features</strong> of recipients of a given country</p>

                  </div>

                  <div  className="column">
                  <div style={{textAlign:'center'}}>
                    <img className="atut" loading="lazy" src="/img/fa-icons/store-solid.svg" style={{margin:'10px',}} width="50px" height="50px" alt="Product descriptions in Polish, SEO for websites"/>
                    </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}> We <strong>perfectly know</strong> the specificity of e-commerce and marketplace </p>

                  </div>

                  <div  className="column">
                  <div style={{textAlign:'center'}}>
                  <img className="atut" loading="lazy" src="/img/fa-icons/search-location-solid.svg" style={{margin:'10px',}} width="50px" height="50px" alt="multilingual content for websites - SEO copywriting"/>
                  </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}> Our texts are <strong>SEO optimized</strong> without compromising their quality </p>

                  </div>

                  <div className="column">
                  <div style={{textAlign:'center'}}>
                    <img className="atut" loading="lazy" src="/img/fa-icons/globe-europe-solid.svg" style={{margin:'10px',}} width="50px" height="50px" alt="copywriters from around the world experienced in SEO and e-commerce"/>
                    </div>
                    <br></br>
                    <p style={{fontSize:'14px'}}> We operate <strong>locally and globally</strong>. </p>
                  </div>

                  <br></br>
                  </div>

                </div>






                </div>

                <div style={{backgroundColor:'#F5F5F2',textAlign:'center',color:'#222222'}}>


                {/* wykres */}
                <br />
                <h3 className='has-text-weight-semibold is-size-2'><span style={{color:'#36b3d2'}}>What do our clients say </span>&nbsp;about us?</h3>
                <SliderOpinions />
                <br></br>








                <h4 style={{paddingBottom:'10px'}}> Global retail sales in e-commerce 2014-2026.<sup>*</sup></h4>
                <br></br>

                <div style={{ paddingLeft:'10%',paddingRight:'10%',borderRadius: '20px',left:'0%',width:'100%',margin:'0',color:'#222222'}}>




                <HorizontalBar
                  data={data2}
                  width={70}
                  height={35}
                  options={{ maintainAspectRatio: true, }}

                />

              <sub> <br></br><sub><i> <sup>*</sup>Published by Stephanie Chevalier on September 21, 2022 at statista.com</i></sub>
                <br></br><sub><i>~ Counted in billions of dollars.</i></sub>
                <br></br><br></br><br></br>
                </sub>






          </div>







        </div>















    </section>






    {/*BREAK 1*/}



{/*PORTFOLIO/BLOG*/}

<div>
    <div className="column is-12" style={{textAlign:'right'}}>
      <img loading="lazy" src="/img/portfoliomini.svg" id="portfolio - copywriting w wielu językach seo / e-commerce"  style={{marginRight:'-20px'}}  width="80%" height="80%" alt="portfolio e-multicontent tłumaczenia SEO"/>
      <h3 className="has-text-weight-semibold is-size-2">
        Check our <Link to="/portfolio/" style={{color:'#36b3d2'}}><b>portfolio</b></Link>:
      </h3>
      <p>See the data.</p>

      <div className="column is-12" style={{marginBottom:'50px',marginTop:'20px',textAlign:'right'}} >
      <Link to="/portfolio/" className="btn" style={{fontSize:'1.5em'}} >
      See our projects &nbsp; &nbsp;
      <img width="15px" height="22px" style={{verticalAlign:'middle', marginRight:'5px'}}   alt="portfolio of SEO copywriting services / websites" src='/img/angle-right.svg'/>
      </Link>








      </div>

    </div>
      <br></br>

    <div className="column">
      <img loading="lazy" src="/img/blogmini.svg" style={{marginLeft:'-20px'}}  width="80%" height="80%"  alt="Blog: SEO translation e-commerce copywriting"/>
      <div style={{marginLeft:'5%',marginRight:'5%'}}>
      <h3 className="has-text-weight-semibold is-size-2">
        Check out our <Link to="/blog/" style={{color:'#36b3d2'}}><b>blog</b></Link>:
      </h3>
      <BlogRoll2 />
      </div>
      <br />
      <div className="column is-12 has-text-centered">
        <Link className="btn" to="/blog/" style={{fontSize:'1.5em'}}>
          Read more &nbsp; &nbsp;
          <img width="15px" height="22px" style={{verticalAlign:'middle', marginRight:'5px'}}   alt="a blog about copywriting, e-commerce marketplace, seo and translations" src='/img/angle-right.svg'/>
        </Link>
      </div>
    </div>
    <br></br>

    </div>




















    <div style={{marginLeft:'5%'}}>
    <h3 className="is-size-2"><b>Subscribe to receive</b> <Link style={{color:'#36b3d2',fontWeight:'800'}} to="/ebook/"> our e-book</Link>:</h3>
    <p style={{fontSize:'1em'}}>How to increase e-shop sales, effective product descriptions in five steps.</p>
    </div>
    <br></br>
    <div className="one lazy-background" style={{backgroundColor:'#36b3d2',
    backgroundPosition:'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',}}>


    <div style={{marginBottom:'0px',height:'320px',textAlign:'center',padding:'100px'}}>

      <Link className="btnblog" to="/ebook/" style={{padding:'30px'}}>
        <span role="img" alt="pobierz e-book o e-commerce" aria-label="ebook-emoji">Sign up</span><br></br>
      </Link>
    </div>

    </div>



{/*kontakt
<div style={{backgroundColor:'rgb(245, 245, 245)',padding:'5%'}}>


<div className="is-size-2" style={{fontWeight:'800'}}>
<span style={{color:'#36b3d2'}}>Skontaktuj się</span> z nami:
</div>
<br />
<br />


<div className="columns" style={{margin:'auto'}}>

<div className="column">
<h3 style={{fontSize:'1em',textAlign:'center'}}>Chcesz się z nami skontaktować?<br /> Wypełnij formularz poniżej, możesz również załączyć plik.</h3>
<br />
<FormMsg/>
</div>

<div className="column" style={{position:'relative',textAlign:'center'}}>
<h3 style={{fontSize:'1em'}}>Chcesz otrzymać ofertę dla siebie?<br />Wypełnij brief.</h3>
<br /><br />
<a className="btn" style={{fontSize:'1.5em'}}
href="https://docs.google.com/forms/d/e/1FAIpQLSfZHfwPESZ4qMZljC9_L-WKIuQuAgLA-dadqM1CvjWkKix1cw/viewform"
target="_blank" rel="noopener noreferrer">Brief e-multicontent &nbsp; &nbsp;
<img width="15px" height="auto"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="brief" src='/img/angle-right.svg'/></a>
<br /><br />
<br /><br />
<img src="/img/kontakt.jpg" style={{maxWidth:'550px'}} loading="lazy" alt="brief" width="100%" height="100%" />
</div>

</div>

<div className="columns" style={{marginTop: '50px', paddingBottom: '70px',marginLeft:'3%',textAlign:'center' }}>

<h3 style={{marginLeft:'3%', color:'#222222'}}>Chcesz wysłać maila?</h3>&nbsp;&nbsp;&nbsp;&nbsp;
&nbsp;<h4><a href="mailto:info@e-multicontent.com" rel="noopener noreferrer" className="btn"  style={{marginTop:'-30px',marginBottom:'20px'}} target="_blank">info@e-multicontent.com</a></h4>


<h3 style={{marginLeft:'3%', color:'#222222'}}>Czy może wolisz porozmawiać?</h3>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h4><a href="tel:+48713074517" rel="noopener noreferrer" style={{marginTop:'-30px'}}  className="btn" target="_blank">+48 71 307 45 17</a></h4>
</div>
</div>


*/}








<div  className="box" style={{marginBottom:'0px'}}>
    <div style={{textAlign:'center',padding:'20px'}} >
    <h3 style={{fontSize:'2em',fontWeight:'800'}}>These clients trusted us:</h3>
    </div>
    <div style={{ textAlign: 'center',padding:'40px',borderRadius:'20px',zIndex:'100', color:' #17132b',marginBottom:'20px'}} >

    <AliceCarousel autoPlay infinite disableButtonsControls={true} items={items2} autoPlayInterval="4500"
      responsive={{
        0: {
        items: 1,
    },
    1024: {
        items: 3,
    }}}
      >
      </AliceCarousel>

    </div>




    <h3 align="center" style={{fontSize:'2em',fontWeight:'800'}}><Link className="dedlink" to="/partners/">Partners:</Link></h3>
    <br></br>



    <div style={{ textAlign: 'center',padding:'40px',borderRadius:'0px', marginBottom:'20px', position:'relative'}} >
    <PartnerSlider />
    </div>


    <br></br>


    <p style={{margin:'0% 5%'}}>Online sales is one of the most dynamically developing areas of the economy. People all over the world love online shopping, and the growing number of e-shops with it every month allows you to completely abandon visits to traditional supermarkets. If you run an online store, you know how much potential there is in e-commerce, but also how difficult it is to stand out from the competition.
    <br /><br />
    Here at e-multicontent, we know the inside out of the e-commerce world, and we are more than happy to help you conquer it. First of all - we will prepare unique product descriptions for your online store in Polish, English, German and any other language. Secondly - we will develop SEO-optimized descriptions of categories and subcategories that will increase the visibility of your e-store on the web. Thirdly, we will take care of your blog by regularly publishing valuable and useful entries for your clients. Thanks to these activities, your position in search engines will go up and you will reach new customers for your products (on the Polish and foreign market), but what is more, you will also be able to enjoy the image of an expert in your industry and the status of a reliable, proven seller.
    <br /><br />
    <Link className="dedlink" to="/contact-us/">Contact us</Link>, and we will carry out a <strong>free initial content audit for you and propose content activities</strong> that will help you succeed in the field of e-commerce.
    </p>
    <br /><br />
    <div style={{textAlign:'center'}}>
      <a className="btnoutline" href="/#up">
      ⇪

      </a>



    <br></br>


    </div>
    </div>




    <div
      className="full-width-image has-text-centered margin-top-0 two lazy-background"
      style={{
        backgroundPosition: `center right`,
        backgroundAttachment: `fixed`,
        height:'400px',
        width:'100%',
      }}
    >
    </div>

  </div>
  </Layout>
  </>
)
}

}




const items2 = [
  <a className="partners" href="https://www.dpd.com/pl/pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/dpd-logo.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="DPD"/></a>,
  <a className="partners" href="https://www.bimago.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/bimago-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="Bimago"/></a>,
  <a className="partners" href="https://borntoflytravel.com/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/born2fly-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="Borntofly"/></a>,
  <a className="partners" href="https://greenpress.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/greenpress-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="Greenpress"/></a>,
  <a className="partners" href="https://www.karko.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/karko-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="Karko"/></a>,
  <a className="partners" href="https://cleantle.com" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/cleantech-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="CleanTech"/></a>,
  <a className="partners" href="https://orsay.com" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/orsay-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="Orsay"/></a>,
  <a className="partners" href="https://www.timsa.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/tim-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="tim"/></a>,
  <a className="partners" href="https://bodyfuel.ie" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/bodyfuel-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="bodyfuel"/></a>,
  <a className="partners" href="https://eko-light.com/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/milagro-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="milagro"/></a>,
  <a className="partners" href="https://eko-light.com/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/eko-light.png" style={{margin:'20px'}} width="180px" height="90px" alt="eko-light"/></a>,
  // nowe - podobno nie znajduje
  <a className="partners" href="https://eko-light.com/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/foen.png" style={{margin:'20px'}} width="180px" height="90px" alt="Foen"/></a>,
  <a className="partners" href="https://myfamilyhome.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/logo_myfamilyhome.png" style={{margin:'20px'}} width="180px" height="90px" alt="My Family Home"/></a>,
  // z biblioteki mediów (złe proporcje miały być 200 x 100)
  <a className="partners" href="https://drmagic.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/drmagic.png" style={{margin:'20px'}} width="180px" height="90px" alt="Dr. Magic"/></a>,
  <a className="partners" href="https://mexen.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/mexen-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="Mexen"/></a>,
  <a className="partners" href="https://conte.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/conte.png" style={{margin:'20px'}} width="180px" height="90px" alt="Conte"/></a>,
  <a className="partners" href="https://triumph.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/triumphxsloggi.png" style={{margin:'20px'}} width="180px" height="90px" alt="Triumph x Sloggi"/></a>,
  <a className="partners" href="https://revise.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/logo-revise.png" style={{margin:'20px'}} width="180px" height="90px" alt="Revise"/></a>,
  <a className="partners" href="https://brenor.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/brenor-logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="Brenor"/></a>,
  <a className="partners" href="https://melagu.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/melagu1.png" style={{margin:'20px'}} width="180px" height="90px" alt="Melagu"/></a>,
  <a className="partners" href="https://komak.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/komak.png" style={{margin:'20px'}} width="180px" height="90px" alt="Komak"/></a>,
  <a className="partners" href="https://allboards.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/allboards.png" style={{margin:'20px'}} width="180px" height="90px" alt="All boards"/></a>,
  <a className="partners" href="https://smartspace.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/logo-smartspace.png" style={{margin:'20px'}} width="180px" height="90px" alt="Smartspace"/></a>,
  <a className="partners" href="https://de-dietrich.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/bdr-thermea-group-dd-baxi_logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="BDR Thermea"/></a>,
  <a className="partners" href="https://multisunlogo.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/multisunlogo3.png" style={{margin:'20px'}} width="180px" height="90px" alt="Multisun"/></a>,
  <a className="partners" href="https://mikroel.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/mikroel.png" style={{margin:'20px'}} width="180px" height="90px" alt="Mikroel"/></a>,
  <a className="partners" href="https://pdca.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/pdca_logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="PDCA"/></a>,
  <a className="partners" href="https://zielonynurt.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/logo.png" style={{margin:'20px'}} width="180px" height="90px" alt="Zielony Nurt"/></a>,
  <a className="partners" href="https://wodamoda.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/wodamoda.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="WODAMODA"/></a>,
  //jeszze więcej logotypów + 30
  <a className="partners" href="https://castorama.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/castorama.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="castorama"/></a>,
  <a className="partners" href="https://blomus.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/blomus.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="blomus"/></a>,
  <a className="partners" href="https://ecorson.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/ecorson.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Ecorson"/></a>,
  <a className="partners" href="https://electro-welle.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/electro-welle.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Electro Welle"/></a>,
  <a className="partners" href="https://energia-port.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/energiaport.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Energia Port"/></a>,
  <a className="partners" href="https://grupa-rb.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/grupa-rb.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="GRUPA RB"/></a>,
  <a className="partners" href="https://homebook.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/homebook.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Homebook"/></a>,
  <a className="partners" href="https://horus.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/horus.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Horus"/></a>,
  <a className="partners" href="https://interdoor.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/interdoor.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Interdoor"/></a>,
  <a className="partners" href="https://krasnale.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/krasnale.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Krasnale"/></a>,
  <a className="partners" href="https://mangomica.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/mangomica.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Mangomica"/></a>,
  <a className="partners" href="https://marchewka.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/marchewka.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Marchewka"/></a>,
  <a className="partners" href="https://mii.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/mii.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Mii"/></a>,
  <a className="partners" href="https://minka.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/minka.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Minka"/></a>,
  <a className="partners" href="https://nad-odra.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/nad-odra.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Nad Odrą"/></a>,
  <a className="partners" href="https://neonail.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/neonail.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Neonail"/></a>,
  <a className="partners" href="https://o12.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/o12.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="o12"/></a>,
  <a className="partners" href="https://po.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/po.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="PO"/></a>,
  <a className="partners" href="https://power.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/power.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Power"/></a>,
  <a className="partners" href="https://simple-software.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/simple-software.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Simple software"/></a>,
  <a className="partners" href="https://sitting-bull.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/sitting-bull.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Sitting Bull"/></a>,
  <a className="partners" href="https://smaki-wroclawia.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/smaki-wroclawia.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Smaki Wrocławia"/></a>,
  <a className="partners" href="https://sofath.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/sofath.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Sofath"/></a>,
  <a className="partners" href="https://studio.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/studio.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Studio"/></a>,
  <a className="partners" href="https://trii.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/trii.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Trii"/></a>,
  <a className="partners" href="https://unit4.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/unit4.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Unit4"/></a>,
  <a className="partners" href="https://vitcas.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/vitcas.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Vitcas"/></a>,
  <a className="partners" href="https://volcano.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/volcano.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Volcano"/></a>,
  <a className="partners" href="https://wroclaw.pl/" target="_blank" rel="noopener noreferrer nofollow"><img loading="lazy" src="/img/wroc.jpg" style={{margin:'20px'}} width="180px" height="90px" alt="Wrocław"/></a>,
];





/*
function animateIcons(){





      let i = 0;

      setInterval(()=>{
        if (document.getElementById('atuty')!==null){
        let icons = document.getElementsByClassName('atut');
        for(let o=0;o<icons.length;o++){
          icons[o].style.animation = '';
        }
        if(i < 10){
          icons[i].style.animation='ollie 2s 1';
          i=i+1;
        }
        else{
          i=0;
          icons[i].style.animation='ollie 2s 1';
          i=i+1;
        }

      }}, 2000);


}
*/
